const config = {
  name: 'Próximo',
  operator: 'proximo',
  operatorID: 'PROXIMO',
  agencyID: 131,
  info: {
    email: 'proximo@proximo.pt',
    phone: '+351 289 899 760',
    support_email: 'proximo@proximo.pt',
    support_phone: '+351 289 899 760',
    recruitment_email: 'proximo@proximo.pt',
    copyright: '©',
    zone_map:
      'https://ubirider-operator-websites-schedules.s3.eu-west-1.amazonaws.com/proximo/zone_map/FARO+-+Proximo_Zone_Map.pdf',
  },
  pass_requests: {
    specific_pass_warning: ['Estudante'],
  },
  sections: {
    about: true, // About page
    ads: {
      mastercard: true,
      pass_migration: true,
      pick: true,
      trevo_fare_update: false,
      proximo_satisfaction_survey: false,
      trevo_route_change: false,
      trevo_fair_schedules: false,
    },
    cookie_policy: true, // Cookies policy and cookie permission dialog
    faqs: true, // FAQs page
    funding: true, // Additional info below footer
    migration: true, // Pass migration page
    news: false, // News page
    pass_requests: true, // Enables requesting travel cards
    pass_renewal: true, // Enables renewing travel cards
    pass_requirements: false, // Pass Requirements
    passenger_rights: false, // Passenger's rights page
    pass_request_policy: true, // Pass Request Data Protection Policy
    pass_migration: true, // Additional info before ticket rates
    pick: true, // Toggles all components related to the PICK app
    privacy_policy: true, // Privacy Policy page
    promotion: true, // Promotion page
    recruitment: true, // Recruitment page
    reviews: false, // Passenger reviews on the home page
    schedules: { modal: true, download: true }, // Schedules modal
    social_support_info: false, // Social support info component
    support: true, // Customer Support page
    terms_conditions: false, // Terms and Conditions page
    ticket_agents: 2, // Ticket Agents component
    ticket_info: true, // Additional info before ticket rates
    ticket_rates: true, // Ticket Prices
    zone_map: true, // Zone Map for Routes to display in Tickets & Fares and Network & Schedules
  },
  mainTitleColor: { sm: '#475467', lg: '#FFFFFF' },
  mainColor: '#0875BC',
  hoverColor: '#F3FAFE',
  progressStyle:
    'linear-gradient(to right, rgba(43, 137, 199, 0), rgba(43, 137, 199, 1), rgba(43, 137, 199, 1), rgba(43, 137, 199, 0))',
  themeTokens: {
    colors: {
      primaryA20: 'rgba(80, 159, 211, 0.2)',
      primaryA40: 'rgba(80, 159, 211, 0.4)',
      primary25: '#F3FAFE',
      primary50: '#E1F1FB',
      primary100: '#CFE7F7',
      primary200: '#ABD4EF',
      primary300: '#75B4DE',
      primary400: '#509FD3',
      primary500: '#2B89C7',
      primary600: '#0875BC',
      primary700: '#06619C',
      primary800: '#03426B',
      primary900: '#002239',
    },
  },
}

export default config
